import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledClientsBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [50]
  })
)

export const styledClients = css`
  ${styledClientsBps};
  h4 {
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }
`

export const H3HeaderBps = css(
  mq({
    maxWidth: [328, 422, 680, 680],
    marginLeft: [0, 89, 200, 302],
    marginRight: [0, 89, 200, 302],
    marginTop: [8],
    marginBottom: [16],
    letterSpacing: [0.25],
    textAlign: ['center'],
    fontSize: [33.6, 33.6, 33.6, 47.9],
    fontWeight: [600, 600, 600, 'normal']
  })
)

export const H6HeaderBps = css(
  mq({
    maxWidth: [328, 422, 680, 680],
    marginLeft: [0, 89, 200, 302],
    marginRight: [0, 89, 200, 302],
    textAlign: ['center']
  })
)

export const header = css`
  display: flex;
  text-align: center;
  h3 {
    ${H3HeaderBps};
    color: rgba(0, 0, 0, 0.87);
  }
  h4 {
    color: rgba(0, 0, 0, 0.87);
  }
  h6 {
    ${H6HeaderBps};
    color: rgba(0, 0, 0, 0.6);
  }
`
export const styleTestimony = css`
  background-color: #fff;
`
export const ctaText = css`
  font-size: 11.8px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.4px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
`

export const video = css`
  -webkit-border-top-left-radius: 12px;
  -webkit-border-bottom-left-radius: 12px;
  -moz-border-radius-topleft: 12px;
  -moz-border-radius-bottomleft: 12px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
`