import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'
import ds from '../theme'
import { useMediaQuery } from 'react-responsive'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from './../components/Div'
import H3 from '../components/H3'
import H4 from '../components/H4'
import H6 from './../components/H6'
import Body2 from '../components/Body2'
import { css } from '@emotion/core'

import { Container, mq } from '../components/styles/Layout.styles'
import { 
  styledClients,
  styleTestimony,
  header,
  ctaText,
  video
} from '../components/styles/Clients.styles'
import Button from './../components/Button/Button'

import GrupoParvi from '../images/clients/parvi.png'
import CBA from '../images/clients/logo-cba.png'
import Parvi from '../images/clients/grupo-parvi.jpg'
import Aiam from '../images/clients/aiam.png'
import Basis from '../images/clients/basis.jpg'
import BateriasMoura from '../images/clients/baterias-moura.png'
import Bentonisa from '../images/clients/bentonisa.jpg'
import Brasimpex from '../images/clients/brasimpex .jpg'
import Britania from '../images/clients/britania.png'
import CamaraJp from '../images/clients/camara-jp.png'
import CartoriaAzevedoBastos from '../images/clients/cartorio-azevedo-bastos.png'
import Casatudo from '../images/clients/casatudo.png'
import Cbatech from '../images/clients/cbatech.png'
import ChilliBeans from '../images/clients/chilli-beans.png'
import CienciasMedicasPb from '../images/clients/ciencias-medicas-pb.png'
import ClubeTurismo from '../images/clients/clube-turismo.jpg'
import CoifeOdonto from '../images/clients/coife-odonto.png'
import ColengioAntares from '../images/clients/colegio-antares.jpg'
import Conductor from '../images/clients/conductor.png'
import Contreina from '../images/clients/contreina .jpg'
import DbDireito from '../images/clients/db-direto.jpg'
import DiretrixOn from '../images/clients/diretrix-on.jpg'
import Ecologica from '../images/clients/ecologica.png'
import EGen from '../images/clients/e-gen.jpg'
import EleveHost from '../images/clients/eleva-host.png'
import Elizabeth from '../images/clients/elizabeth.png'
import Equilibrio from '../images/clients/equilibrio.png'
import FaculdadeCescage from '../images/clients/faculdade-cescage.jpg'
import FaculdadeFutura from '../images/clients/faculdade-futura.jpg'
import FaculdadeSouza from '../images/clients/faculdade-souza.png'
import FaculdadeUnicampo from '../images/clients/faculdade-unicampo.jpg'
import Fan from '../images/clients/fan.png'
import Fps from '../images/clients/fps.png'
import GrupoAssesi from '../images/clients/grupo-assesi.jpg'
import GrupoHorizonteOriginal from '../images/clients/grupo-horizonte-original.png'
import HospitalMemorialSF from '../images/clients/hospital-memorial-sao-francisco.png'
import HospitalRegionalDeFranca from '../images/clients/hospital-regional-de-franca.jpg'
import Iland from '../images/clients/iland.png'
import InstitutoAdvogados from '../images/clients/instituto-advogados.png'
import Jotur from '../images/clients/jotur.jpg'
import K2Host from '../images/clients/k2host.png'
import KurierTec from '../images/clients/kurier-tecnologia.png'
import LinkSolution from '../images/clients/link-solutions.jpg'
import MarcosInacio from '../images/clients/marcos_inacio.png'
import Melita from '../images/clients/melita.png'
import MemorialDiagnostico from '../images/clients/memorial-diagnostico.png'
import NovaVidaTi from '../images/clients/nova-vida-ti.jpg'
import OAB from '../images/clients/oab.png'
import OABPe from '../images/clients/oab_pe.png'
import OmegaSistemas from '../images/clients/omega-sistemas.png'
import ReiAlimentos from '../images/clients/rei-alimentos.jpg'
import RogerioMenezesLeiloiro from '../images/clients/rogerio-menezes-leiloeiro.jpg'
import Roval from '../images/clients/roval.png'
import Sebrae from '../images/clients/sebrae.png'
import ServHost from '../images/clients/servhost.png'
import SescPb from '../images/clients/sesc-paraiba.png'
import Sintese from '../images/clients/sintese.png'
import SociedadeBrDeCbm from '../images/clients/socieade-brasileira-de-cbm.jpg'
import StaloDigital from '../images/clients/stalo-digital.jpg'
import SuporteOne from '../images/clients/suporte-one.jpg'
import TomoVale from '../images/clients/tomovale.jpg'
import Unifarma from '../images/clients/unifamma.png'
import MistGames from '../images/clients/mist-games.png'
import Selfit from '../images/clients/selfit.png'
import Carousel from '../components/Carousel'

const clients = [
  {
    name: 'Grupo Parvi',
    logo: Selfit
  },
  {
    name: 'Grupo Parvi',
    logo: Parvi
  },
  {
    name: 'Grupo Parvi',
    logo: Aiam
  },
  {
    name: 'Grupo Parvi',
    logo: Basis
  },
  {
    name: 'Grupo Parvi',
    logo: BateriasMoura
  },
  {
    name: 'Grupo Parvi',
    logo: Bentonisa
  },
  {
    name: 'Grupo Parvi',
    logo: Brasimpex
  },
  {
    name: 'Grupo Parvi',
    logo: Britania
  },
  {
    name: 'Grupo Parvi',
    logo: CamaraJp
  },
  {
    name: 'Grupo Parvi',
    logo: CartoriaAzevedoBastos
  },
  {
    name: 'Grupo Parvi',
    logo: Casatudo
  },
  {
    name: 'Grupo Parvi',
    logo: Cbatech
  },
  {
    name: 'Grupo Parvi',
    logo: ChilliBeans
  },
  {
    name: 'Grupo Parvi',
    logo: CienciasMedicasPb
  },
  {
    name: 'Grupo Parvi',
    logo: ClubeTurismo
  },
  {
    name: 'Grupo Parvi',
    logo: CoifeOdonto
  },
  {
    name: 'Grupo Parvi',
    logo: ColengioAntares
  },
  {
    name: 'Grupo Parvi',
    logo: Conductor
  },
  {
    name: 'Grupo Parvi',
    logo: Contreina
  },
  {
    name: 'Grupo Parvi',
    logo: DbDireito
  },
  {
    name: 'Grupo Parvi',
    logo: DiretrixOn
  },
  {
    name: 'Grupo Parvi',
    logo: Ecologica
  },
  {
    name: 'Grupo Parvi',
    logo: EGen
  },
  {
    name: 'Grupo Parvi',
    logo: EleveHost
  },
  {
    name: 'Grupo Parvi',
    logo: Elizabeth
  },
  {
    name: 'Grupo Parvi',
    logo: Equilibrio
  },
  {
    name: 'Grupo Parvi',
    logo: FaculdadeCescage
  },
  {
    name: 'Grupo Parvi',
    logo: FaculdadeFutura
  },
  {
    name: 'Grupo Parvi',
    logo: FaculdadeSouza
  },
  {
    name: 'Grupo Parvi',
    logo: FaculdadeUnicampo
  },
  {
    name: 'Grupo Parvi',
    logo: Fan
  },
  {
    name: 'Grupo Parvi',
    logo: Fps
  },
  {
    name: 'Grupo Parvi',
    logo: GrupoAssesi
  },
  {
    name: 'Grupo Parvi',
    logo: GrupoHorizonteOriginal
  },
  {
    name: 'Grupo Parvi',
    logo: HospitalMemorialSF
  },
  {
    name: 'Grupo Parvi',
    logo: HospitalRegionalDeFranca
  },
  {
    name: 'Grupo Parvi',
    logo: Iland
  },
  {
    name: 'Grupo Parvi',
    logo: InstitutoAdvogados
  },
  {
    name: 'Grupo Parvi',
    logo: Jotur
  },
  {
    name: 'Grupo Parvi',
    logo: K2Host
  },
  {
    name: 'Grupo Parvi',
    logo: KurierTec
  },
  {
    name: 'Grupo Parvi',
    logo: LinkSolution
  },
  {
    name: 'Grupo Parvi',
    logo: MarcosInacio
  },
  {
    name: 'Grupo Parvi',
    logo: Melita
  },
  {
    name: 'Grupo Parvi',
    logo: MemorialDiagnostico
  },
  {
    name: 'Grupo Parvi',
    logo: NovaVidaTi
  },
  {
    name: 'Grupo Parvi',
    logo: OAB
  },
  {
    name: 'Grupo Parvi',
    logo: OABPe
  },
  {
    name: 'Grupo Parvi',
    logo: OmegaSistemas
  },
  {
    name: 'Grupo Parvi',
    logo: ReiAlimentos
  },
  {
    name: 'Grupo Parvi',
    logo: RogerioMenezesLeiloiro
  },
  {
    name: 'Grupo Parvi',
    logo: Roval
  },
  {
    name: 'Grupo Parvi',
    logo: Sebrae
  },
  {
    name: 'Grupo Parvi',
    logo: ServHost
  },
  {
    name: 'Grupo Parvi',
    logo: SescPb
  },
  {
    name: 'Grupo Parvi',
    logo: Sintese
  },
  {
    name: 'Grupo Parvi',
    logo: SociedadeBrDeCbm
  },
  {
    name: 'Grupo Parvi',
    logo: StaloDigital
  },
  {
    name: 'Grupo Parvi',
    logo: SuporteOne
  },
  {
    name: 'Grupo Parvi',
    logo: TomoVale
  },
  {
    name: 'Grupo Parvi',
    logo: Unifarma
  }
]

const testimonials = [
  {
    title: 'Clube Turismo',
    text: '"A parceria com a HostDime foi um marco para a rede Clube Turismo. Somos imensamente gratos pela parceria e pelo suporte excepcional.”',
    officeName: 'Vitor Fernandes',
    officePost: 'IT Manager e Web Developer',
    logo: ClubeTurismo
  },
  {
    title: 'Mist Games',
    text: '“A HostDime apareceu como uma grande solução, começando pela qualidade e eficiência no atendimento do setor comercial que se estende para demais áreas.”',
    officeName: 'Bruno Rogrigues',
    officePost: 'Diretor Operacional',
    logo: MistGames
  },
  {
    title: 'ServHost',
    text: '"A infraestrutura disponibilizada pela Hostdime nos dá muita tranquilidade e faz com que tenhamos total condições de oferecer os melhores serviços aos nossos clientes.”',
    officeName: 'Leonardo Eliodoro',
    officePost: 'Diretor de finanças',
    logo: ServHost
  },
  {
    title: 'ServHost',
    text: '"A infraestrutura disponibilizada pela Hostdime nos dá muita tranquilidade e faz com que tenhamos total condições de oferecer os melhores serviços aos nossos clientes.”',
    officeName: 'Leonardo Eliodoro',
    officePost: 'Diretor de finanças',
    logo: ServHost
  }
]

const testimonialsVideos = [
  {
    video: 'https://www.youtube.com/embed/SjDTEr1jrto',
    text: "“Além da infraestrutura e da disponibilidade de recursos, a latência foi um ponto crucial para que nós fechássemos essa parceria.”",
    author: 'Daniel Albuquerque',
    authorDesc: 'Diretor de TI do Grupo Parvi',
    logo: GrupoParvi
  },
  {
    video: 'https://www.youtube.com/embed/RE7tZMhFtuw',
    text: "“O diferencial da HostDIme não é por que ela é apenas um Data Center. É por que ela entende o negócio da CBA.”",
    author: 'Adriano Gomper',
    authorDesc: 'CEO da CBA Tech',
    logo: CBA
  }
]

const fistChildMargin = css(
  mq({
    '& > div:first-child': {
      marginRight: [16, 16, 20]
    }
  })
)

const ClientsList = ({
  limit,
  list
}) => {
  const totalRows = Math.ceil(list.length / limit)
  const [rowCount, setRowCount] = useState(4)
  const isMobileOrTablet = useMediaQuery({ query: '(max-width: 840px)' })

  const responsiveCount = isMobileOrTablet
    ? rowCount - 2
    : rowCount

  const showMore = () => {
    if (responsiveCount < totalRows) {
      setRowCount(rowCount + 1)
    }
  }

  return (
    <>
      {
        Array(responsiveCount).fill(0).map((row, i) => {
          const min = (i * limit)
          const max = (i * limit) + limit
          const range = list.slice(min, max)
          const half = range.slice(0, Math.ceil(range.length / 2))
          const otherHalf = range.slice(Math.ceil(range.length / 2))
          return (
            <Fragment
              key={`clientrow-${i}`}
            >
              <Row
                flexDirection={['column', 'column', 'row']}
              >
                <Div
                  maxWidth={['100%', 328, 680, 700]}
                  style={{
                    margin: '0 auto',
                    textAlign: 'center',
                    flexDirection: 'inherit'
                  }}
                >
                  <Column
                    width={[1, 1, 1/2, 1/2]}
                    mb={16}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                    css={fistChildMargin}
                  >
                    {half.map((client, y) => (
                      <Fragment
                        key={`listitem-${i}-${y}`}
                      >
                        {client.logo
                          ? (
                            <div
                              style={{
                                width: 156,
                                height: 156
                              }}
                            >
                              <img
                                src={client.logo}
                                alt={client.name}
                                style={{
                                  objectFit: 'cover',
                                  width: 156,
                                  height: 156,
                                  lineHeight: 1
                                }}
                              />
                            </div>
                          )
                          : (
                            <div
                              style={{
                                width: 156,
                                height: 156,
                                backgroundColor: ds.brand('iceBlue')
                              }}
                            />
                          )
                        }
                      </Fragment>
                    ))}
                  </Column>
                  {otherHalf.length > 0 && (
                    <Column
                      width={[1, 1, 1/2, 1/2]}
                      mb={16}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                      css={fistChildMargin}
                    >
                      {otherHalf.map((client, y) => (
                        <Fragment
                          key={`listitemb-${i}-${y}`}
                        >
                          {client.logo
                            ? (
                              <div
                                style={{
                                  width: 156,
                                  height: 156
                                }}
                              >
                                <img
                                  src={client.logo}
                                  alt={client.name}
                                  style={{
                                    objectFit: 'cover',
                                    width: 156,
                                    height: 156,
                                    lineHeight: 1
                                  }}
                                />
                              </div>
                            )
                            : (
                              <div
                                style={{
                                  width: 156,
                                  height: 156,
                                  backgroundColor: ds.brand('iceBlue')
                                }}
                              />
                            )
                          }
                        </Fragment>
                      ))}
                    </Column>
                  )}
                </Div>
              </Row>
            </Fragment>
          )})
      }
      {responsiveCount < totalRows && (
        <Button
          schema='white'
          outlined
          selected
          variant='base'
          on='onSurface'
          style={{
            display: 'flex',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            margin: '14px auto 0',
            width: '100%',
            maxWidth: '180px',
            color: 'rgb(115, 116, 116)'
          }}
          onClick={showMore}
        >
          carregar mais
        </Button>
      )}
    </>
  )
}

const ClientItem = ({
  title,
  text,
  officeName,
  officePost,
  logo
}) => {
  return (
    <div
      style={{
        padding: '50px 40px',
        backgroundColor: '#FFFFFF',
        borderRadius: 12,
        border: '1px solid rgba(0, 0, 0, 0.12)'
      }}
      css={
        [css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        )
      ]
      }
    >
      <Div
        flexDirection='column'
        alignItems='center'
      >
        <div>
          <img src={logo} 
            style={{ 
              width: 139,
              borderRadius: '50%', 
              marginBottom: 24 
            }} 
          />
        </div>
        <H6
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            letterSpacing: 0.25,
            marginBottom: 24
          }}
        >
          {title}
        </H6>
        <Body2 
          style={{ 
            marginBottom: 24, 
            minHeight: 144,
            textAlign: 'center',
            lineHeight: 1.71,
            color: 'rgba(0, 0, 0, 0.87)'
          }}>
          {text}
        </Body2>
        <div css={ctaText}>
          {officeName}
        </div>
        <div css={ctaText}>
          {officePost}
        </div>
      </Div>
    </div>
  )
}

const Videos = ({
  items,
}) => {
  const matches = useMediaQuery({ query: '(min-width: 840px)' })
  const maxItems = 1
  const pages = Math.ceil(items.length / maxItems)
  return (
    <Carousel
      open
      hideArrows
      mobileDots={!matches}
      autoplay={false}
    >
      {Array(pages).fill(0).map((item, i) => i).map((page, i) => {
        const min = (i * maxItems)
        const max = (i * maxItems) + maxItems
        return (
          <Row
            mt={[30, 50]}
            style={{
              marginLeft: 0,
              marginRight: 0
            }}
          >
            {items.slice(min, max).map((item, i) => (
              <Div
                flexDirection={['column', 'column', 'row']}
                maxWidth={['100%', 422, 680, 1032]}
                style={{
                  margin: '0 auto',
                  textAlign: 'center',
                  padding: 0,
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  borderRadius: 12
                }}
              >
                <Column
                  width={[1, 1, 1/2]}
                  px={null}
                >
                  <Div
                    maxWidth={['100%', 422, 680, 813]}
                  >
                    <iframe css={video} width="100%" height="415" src={item.video} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" />
                  </Div>
                </Column>
                <Column
                  px={null}
                  width={[1, 1, 1/2]}
                  pt={[40, 40, 0, 0]}
                  pb={[40, 40, 0, 0]}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <img
                    src={item.logo} 
                    style={{
                      width: 150,
                      marginBottom: 24
                    }} 
                  />
                  <Body2
                    style={{
                      width: 328,
                      color: 'rgba(0, 0, 0, 0.87)',
                      textAlign: 'center',
                      marginBottom: 24
                    }}
                  >
                    {item.text}
                  </Body2>
                  <div css={ctaText}>
                    {item.author}
                  </div>
                  {item.authorDesc && <div css={ctaText}>
                    {item.authorDesc}
                  </div>}
                </Column>
              </Div>
            ))}
          </Row>
        )
      })}
    </Carousel>
  )
}

const Testimonials = ({
  items,
}) => {
  const matches = useMediaQuery({ query: '(min-width: 840px)' })
  const maxItems = matches ? 3 : 1
  const pages = Math.ceil(items.length / maxItems)
  return (
    <Carousel
      open
      hideArrows
      mobileDots={!matches}
    >
      {Array(pages).fill(0).map((item, i) => i).map((page, i) => {
        const min = (i * maxItems)
        const max = (i * maxItems) + maxItems
        return (
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row', 'row']}
            alignItems={['center', 'center', 'flex-start']}
            key={`carouselpage-${i}`}
            style={{
              margin: 0
            }}
          >
            {items.slice(min, max).map((item, i) => (
              <Column
                mb={[16, 16, 16, 0]}
              >
                <Div
                  maxWidth={['100%', 328]}
                >
                  <ClientItem
                    key={`carouselitem-${i}`}
                    title={item.title}
                    text={item.text}
                    officeName={item.officeName}
                    officePost={item.officePost}
                    logo={item.logo}
                  />
                </Div>
              </Column>
            ))}
          </Row>
        )
      })}
    </Carousel>
  )
}

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <Fragment>
      <section css={styledClients}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H3>Confiança e Parceria</H3>
              <H6>Essa é a forma HostDime <br />de atender bem nossos clientes.</H6>
            </Column>
          </Row>
          <Videos items={testimonialsVideos} />
        </Container>
      </section>
      <section css={styleTestimony}>
        <Container>
          {testimonials.length <= 3
            ? (
              <Row
                justifyContent={'center'}
                flexDirection={['column', 'column', 'row', 'row']}
                alignItems={['center', 'center', 'flex-start']}
              >
                {testimonials.map((item) => (
                  <Column
                    mb={[16, 16, 16, 0]}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                    >
                      <ClientItem
                        title={item.title}
                        text={item.text}
                        officeName={item.officeName}
                        officePost={item.officePost}
                        logo={item.logo}
                      />
                    </Div>
                  </Column>
                ))}
              </Row>
            )
            : (
              <Testimonials
                items={testimonials}
              />
            )}
        </Container>
      </section>
      <section>
        <Container
          style={{
            marginTop: 50,
            marginBottom: 100
          }}
        >
          <Row
            justifyContent={'center'}
            mt={50}
            mb={50}
          >
            <Div
              maxWidth={['100%', '100%', 680, 680]}
            >
              <H4
                style={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  textAlign: 'center'
                }}
              >Mais de 50 mil organizações em todo o mundo confiam na HostDime.</H4>
            </Div>
          </Row>
          <ClientsList
            list={clients}
            limit={4}
          />
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Confiança e Parceria' />
      <PageTemplate
        title='Confiança e Parceria'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
